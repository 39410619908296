<template>
  <div class="container">
    <div ref="bar" class="risk" style="height:40px">
      <div class="title">
        {{title}}
      </div>
      <div class="number" :style="'background:' + clr(value)" @click="toggleBars()">
        {{newVal(value)}}
      </div>
      <div  class="bars-container" @click="toggleBars()">
        <div class="bars" :class="toggle ? 'invisible' : 'visible'" >
          <RiskBar v-for="(bar, index) in bars" :key="bar.title + index" :title="bar.title" :value="newPercent(bar.value)" :clr="clr(bar.value)" :type="bar.value != 0 ? 'vertical' : 'empty'" />
        </div>
        <div class="risk-items" :class="toggle ? 'visible' : 'invisible'">
          <RiskItem v-for="(bar, index) in listlist" :key="bar.title + index" :title="bar.title" :value="newVal(bar.value)"/>
        </div>
      </div>
      <div class="bars-container" @click="toggleBars()">
        <div class="bars-horizontal" :class="toggle ? 'invisible' : 'visible'">
          <RiskBar v-for="(bar, index) in bars" :key="bar.title + index" :title="bar.title" :value="newPercent(bar.value)" :clr="clr(bar.value)" :type="bar.value != 0 ? 'horizontal' : 'empty'"/>
        </div>
        <div class="risk-items" :class="toggle ? 'visible' : 'invisible'">
          <RiskItem v-for="(bar, index) in listlist" :key="bar.title + index" :title="bar.title" :value="newVal(bar.value)"/>
        </div>
      </div>

      <!--div class="number">
        {{getAggregatedValue(list.title)}}
      </div>
      <div class="bars">
        <RiskBar v-for="l in list" :key="l" :data="getValue(list.title, place.title)" :type="'vertical'"/>
      </div>
      <div class="bars-horizontal">
        <RiskBar v-for="l in list" :key="l" :data="getValue(list.title, place.title)" :type="'horizontal'"/>
      </div>
      <div class="lists-horizontal">
        <div v-for="list in lists" :key="list.title + 'h'">
          {{list.title}}&nbsp;{{getValue(list.title, place.title).value}}
        </div>
      </div-->
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import RiskBar from '@/components/RiskBar.vue';
import RiskItem from '@/components/RiskItem.vue';
export default {
  name: "RiskBarContainer",
  props: {
    list: Object,
    title: String,
    value: Number,
    bars: [],
    
  },
  components: {
    RiskBar,RiskItem,
  },
  computed: {

    listlist() {
      const list = this.bars.filter(e => e.title !== 'empty');
      return list;
    }
    

  },
  data: function () {
    return {
      number: this.value,
      max: 4,
      min: 0,
      ui: false,
      adjustment: '',
      toggle: false,
      vertical: false,
    }
  },
  methods: {
    /*getValue(arr) {
      const val = this.lists.find(e => e.title === l).values.find(e => e.title === p).value;
      const title = l;
      return {
        list: title,
        value: val
      }
    },
    getAggregatedValue(p) {
      //const titles = this.lists.map(list => list.title);
      const val = this.lists.reduce((acc, pilot) => acc + pilot.values.find(e => e.title === p).value, 0);
      const result = Math.round(val / this.lists.length);
      return result;
    },*/
    

    toggleBars() {
      this.toggle = !this.toggle;
      if(this.toggle) {
        this.$refs.bar.style.height = Math.max(40,(this.listlist.length * 24)) + 'px';
      } else {
        this.$refs.bar.style.height = "40px";
      }
    }, 
    newVal(n) {
      return Math.round(n*4/100);
    },
    newPercent(n) {
      return Math.round(n*4/100) * 100/4;
    },
    clr(n) {
      const c = Math.round(n*4/100);
      let clr;
      switch (true) {
      case (c < 1):
        clr = 'rgba(0,255,0,1)';
        break;
      case (c < 2):
        clr = 'rgba(128,255,0,1)';
        break;
      case (c < 3):
        clr = 'rgba(255,255,0,1)';
        break;
      case (c < 4):
        clr = 'rgba(255,128,0,1)';
        break;
      case (c === 4):
        clr = 'rgba(255,0,0,1)';
        break;

      default:
        clr = 'rgba(0,0,0,1)';
      }
      if (c === 0) {
        clr = 'rgba(0,0,0,0)';
      }

      return clr;
    }
  },
  mounted() {
    this.$refs.bar.style.height = "40px";
  }
};

</script>
<style lang="scss" scoped>
  @import "./../scss/fyr.scss";
  .container {
    position: relative;
    box-sizing: border-box;
    
  }
  .title {
    min-width: 20%;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    font-size: 13px;
  }
  .risk {
    overflow: hidden;
    display: flex;
    align-items: center;
    font-size: 12px;
    flex: 1 1 0;
    //border-top: 4px solid rgba(0,0,0,0.2);
    height: 100%;
    background: rgba(255,255,255,0.9);
    color: black;
    margin: 4px 0;
    transition: 200ms;
  }
  .risk:last-child {
    //border-bottom: 4px solid rgba(255,255,255,0);
  }
  .number {
    width: 36px;
    align-self: stretch;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  .bars {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: flex-end;
    //flex-direction: column;
    //padding: 10px 0;
    width: 100%;
    height: 100%;
    //padding: 4px 4px;
    box-sizing: border-box;
    
    
    
  }
  .bars-horizontal {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    //align-items: flex-end;
    flex-direction: column;
    //padding: 10px 0;
    width: 100%;
    height: 100%;
    //padding: 4px 4px;
    box-sizing: border-box;
    background: rgba(255,255,255,0.9);
    border-right: 1px solid rgba(0,0,0,0.16);
  }
  .lists-horizontal {


    display: flex;
    //align-items: flex-end;
    flex-direction: column;
    text-align: left;
    padding: 10px 0;
    width: 20%;
    height: 100%;
    //padding: 4px 4px;
    box-sizing: border-box;
    background: rgba(255,255,255,0.9);
    border-right: 1px solid rgba(0,0,0,0.16);
  }
  .risk-items {
    transition: 200ms;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .invisible {
    opacity: 0;
    pointer-events: none;
  }
  .visible {
    opacity: 1;
  }
  .bars-container {
    position: relative;
    height: 100%;
    width: 25%;
    
  }
</style>

<template>
  <div class="risk-item">
    <div>{{title}}</div><div>{{value}}</div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "RiskItem",
  props: {
    title: String,
    value: Number,
    clr: String,
  },
  methods: {

  }
};

</script>
<style lang="scss" scoped>
  .risk-item {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    height: 24px;
    border-top: 1px solid rgba(0,0,0,0.2);
  }
</style>

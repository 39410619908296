<template>
  <div class="nav">
    <router-link class="link" to="/">Home</router-link>
    <router-link class="link" to="/home">Bad Weather</router-link>
    <router-link class="link" to="/risklistdemo">Risk List Demo</router-link>
  </div>
</template>
<style lang="scss">
  @import "../scss/fyr.scss";
</style>
<script>
export default {
  name: "Nav",
  mounted() {
    window.addEventListener('keypress', this.keyHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keypress', this.keyHandler);
  },
  methods: {
    keyHandler(e) {
      console.log(e.key);
      switch (e.key) {
        case 'q': this.navigate('/');
        break;
        case ' ': this.navigate('/badweather');
        break;
        case 'a': this.navigate('/about');
        break;
        //default: console.log('no function tied to this key');
      }
    },
    navigate(path) {
      if(this.$route.path != path) { this.$router.push(path); }
    }
  },
};
</script>

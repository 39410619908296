<template>
  <div class="container">
    <div v-if="type === 'vertical'" class="bar vertical" :style="'height:' + 100 + '%; background:' + clr" :title="title + ' - ' + value"/>
    <div v-else-if="type === 'horizontal'" class="bar horizontal" :style="'width:' + 100 + '%; background:' + clr" :title="title + ' - ' + value"/>
    <div v-else class="empty"/>
    <div class="top"/>
    <div class="bottom"/>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "RiskBar",
  props: {
    title: String,
    value: Number,
    clr: String,
    type: String,
  },
  components: {
    
  },
  computed: {
    newNumber() {
      return 2;
    }
  },
  data: function () {
    return {

      
    }
  },
  methods: {
    toggle() {
      this.ui = !this.ui;
    },
    adjust(n) {
      this.number = Math.min(Math.max((this.number + n), this.min), this.max);
      const a = this.number - this.value;
      if(a > 0) {
        this.adjustment = '+' + a;
      } else {
        this.adjustment = a;
      }

    }
  }
};
</script>
<style lang="scss" scoped>
  @import "./../scss/fyr.scss";
  .container {
    position: relative;
    width: 100%;
    max-width: 12px;
    height: 100%;
  }
  .top {
    height: 8px;
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 12px;
    background: white;
  }
  .bottom {
    height: 0px;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 12px;
    background: black;
  }
  .bar {
    flex: 1 1 0;
    //background-color: black;
    transition: 400ms;
    //border-right: 1px solid rgba(255,255,255,0.4);
   // margin: 0 1px 0 0;
    box-sizing: border-box;
    -moz-box-shadow:    inset 5px 0 3px -3px rgba(0,0,0,0.5);
    -webkit-box-shadow: inset 5px 0 3px -3px rgba(0,0,0,0.5);
    box-shadow:         inset 5px 0 3px -3px rgba(0,0,0,0.5);
  }
  .vertical {
    flex: 1 1 0;
    background-color: black;
    transition: 400ms;
    //border-right: 1px solid rgba(255,255,255,0.4);
    max-width: 12px;
  }
  .horizontal {
    flex: 1 1 0;
    background-color: black;
    transition: 400ms;
    border-right: 1px solid rgba(255,255,255,0.4);
  }
  .dropShadow {

  }
  .bar:hover {
    transition: 0ms;
    background-color: gold;
  }
  .empty {
    flex: 1 1 0;
    margin: 0 1px 0 0;
    box-sizing: border-box;
    max-width: 12px;
  }
  
</style>

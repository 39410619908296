<template>
  <div class="game-container">
    <div class="readout">FPS {{Math.floor(ticker.FPS)}}</div>
    <div v-for="(au, index) in this.aus" :key="'au' + index" class="au-ui" :style="'left: ' + (((index + 1) / (auNum + 1))*100) + '%; bottom:10px;'">
      <div :class="['ammoIcon', ammoNum(index) >= 1 ? 'active' : '']"/>
      <div :class="['ammoIcon', ammoNum(index) >= 2 ? 'active' : '']"/>
      <div :class="['ammoIcon', ammoNum(index) >= 3 ? 'active' : '']"/>
    </div>
    <div id="pixi-container"></div>
  </div>
</template>
<script>
import * as PIXI from "pixi.js";
import invader from '../images/invader.png';
import invader0 from '../images/invader0.png';
import invader1 from '../images/invader1.png';
import sample from '../images/sample.png';
import json from '../images/TP.json';
import spritesheet from '../images/TP.png';

export default {
  name: "BadWeather",
  props: {
    msg: String,
  },

  data() {
    return {
      loader: PIXI.Loader,
      renderer: PIXI.Renderer,
      stage: PIXI.Container,
      sprite: PIXI.Sprite,
      ticker: PIXI.Ticker,
      threats: [],
      auNum: 3,
      aus: [],
      controls: [],
      explosions: [],
      ammoLimit: 3,
      ammoPerSecond: 1.5,
      pxWidth: 0,
      pxHeight: 0,
      groundPosition: 50,
      epiCenter: {x:0, y:0},
      mouseX: 0,
      mouseY: 0,
    };
  },

  computed: {

  },

  methods: {
    size() {
      this.pxWidth = window.innerWidth;
      this.pxHeight = window.innerHeight;
      this.epiCenter = {x: this.pxWidth / 2, y: this.pxHeight * 0.8}
      this.auPositions = [
        {
          x: this.pxWidth * 0.25, 
          y: this.pxHeight - 40,
        },
        {
          x: this.pxWidth * 0.5, 
          y: this.pxHeight - 40,
        },
        {
          x: this.pxWidth * 0.75, 
          y: this.pxHeight - 40,
        },
      ];
      this.renderer.resize(this.pxWidth, this.pxHeight);
    },
    mousePosition(e) {
      this.mouseX = e.clientX;
      this.mouseY = e.clientY;
    },
    keyDown(e) {
      if (isFinite(e.key)) {
        if(e.key <= this.auNum && e.key != 0) {
          this.createControl(Number(e.key - 1));
        }
      } else {
        if(e.key === 'f') {
          this.auNum ++;
          this.createAU();
        }
      }
    },
    initUI() {
      window.addEventListener("resize", this.size);
      window.addEventListener("mousemove", this.mousePosition);
      document.addEventListener('keydown', this.keyDown);
    },
    initPixi() {
      this.renderer = new PIXI.Renderer({
        width: this.pxWidth,
        height: this.pxHeight,
        antialias: true,
        resolution: window.devicePixelRatio,
        autoDensity: true,
        backgroundAlpha: 0,
      });

      document.querySelector("#pixi-container").appendChild(this.renderer.view);

      this.stage = new PIXI.Container(2000, {rotation: true});
      this.loader = new PIXI.Loader();
      this.loader.onComplete.add(this.loaded);

      this.loader.add("invader", invader);
      this.loader.add("sample", sample);
      this.loader.add("spritesheet", spritesheet);
      this.loader.add("json", json);
      this.loader.load();
    },
    startRender() {
      this.ticker = new PIXI.Ticker();
      this.ticker.add(this.render);
      this.ticker.start();
    },
    render() {
      const ms = this.ticker.deltaMS / 1000;

      // THREATS
      const garbage = [];
      for (const [i, threat] of this.threats.entries()) {
        threat.position.y += threat.speed;
        threat.sprite.y = threat.position.y;
        if(threat.position.y > this.pxHeight - 100) {
          garbage.push(i);
        }
      }
      for (let i = garbage.length -1; i >= 0; i--) {
        this.threats[garbage[i]].sprite.destroy();
        this.threats.splice(garbage[i], 1);
      }

      //AUS
      for (const [a, au] of this.aus.entries()) {
        au.position = {x: (this.pxWidth / (this.auNum + 1)) * (a + 1), y: this.pxHeight - this.groundPosition}
        au.ammo += (this.ammoPerSecond * ms);
        if (au.ammo >= this.ammoLimit) {
          au.ammo = this.ammoLimit;
        }
        au.sprite.x = au.position.x;
        au.sprite.y = au.position.y;
        au.sprite.rotation = Math.atan2(au.position.y - this.mouseY, au.position.x - this.mouseX) - (90 * Math.PI/180);
      }

      //CONTROLS
      const controlGarbage = [];
      for (const [i, control] of this.controls.entries()) {
        control.time += this.ticker.deltaMS;
        const p = this.easeOutQuart(control.time / control.duration);
        control.position.x = control.startPosition.x + (control.endPosition.x - control.startPosition.x) * p;
        control.position.y = control.startPosition.y + (control.endPosition.y - control.startPosition.y) * p;
        control.sprite.x = control.position.x;
        control.sprite.y = control.position.y;
        if (control.time >= control.duration) {
          this.createExplosion({x: control.endPosition.x, y: control.endPosition.y});
          controlGarbage.push(i);
        }
      }
      for (let c = controlGarbage.length -1; c >= 0; c--) {
        this.controls[controlGarbage[c]].sprite.destroy();
        this.controls.splice(controlGarbage[c], 1);
      }

      //EXPLOSIONS
      const explosionGarbage = [];
      for (const [i, explosion] of this.explosions.entries()) {
        explosion.time += this.ticker.deltaMS;
        const p = this.easeOutQuart(explosion.time / explosion.duration);
        explosion.radius = ((explosion.endRadius - explosion.startRadius) * p);
        explosion.sprite.width = explosion.radius;
        explosion.sprite.height = explosion.radius;
        if (explosion.time >= explosion.duration) {
          explosionGarbage.push(i);
        }
      }
      for (let c = explosionGarbage.length -1; c >= 0; c--) {
        this.explosions[explosionGarbage[c]].sprite.destroy();
        this.explosions.splice(explosionGarbage[c], 1);
      }


      // RENDER

      this.stage.sortChildren();
      this.renderer.render(this.stage);
      
      
    },
    loaded() {
      this.startRender();
      this.background();
      this.threatGenerator();
      this.createAUs();
    },

    background() {;
      let rectangle = new PIXI.Graphics();
      rectangle.beginFill(0x000000);
      rectangle.drawRect(0, 0, 64, 64);
      rectangle.endFill();
      this.stage.addChild(rectangle);
    },

    gradient(from, to) {
      const c = document.createElement("canvas");
      const ctx = c.getContext("2d");
      const grd = ctx.createLinearGradient(0,0,100,100);
      grd.addColorStop(0, from);
      grd.addColorStop(1, to);
      ctx.fillStyle = grd;
      ctx.fillRect(0,0,100,100);
      return new PIXI.Texture.from(c);
    },

    threatGenerator() {
      const timer = Math.random() * 1000;
      this.threatTimer = setTimeout(this.createThreat, timer);
    },
    threatTimer() {
      return Math.random() * 5;
    },
    threat() {
      const invaderImages = [invader0, invader1];
      let invaderArray = [];
      for (const image of invaderImages) {
        let texture = PIXI.Texture.from(image);
        invaderArray.push(texture);
      }
      const position = {x: Math.random() * this.pxWidth, y: 0};
      const scale = (Math.random() * 2) + 0.1;
      const sprite = new PIXI.AnimatedSprite(invaderArray);
      sprite.animationSpeed = 0.05;
      sprite.anchor.set(0.5);
      sprite.alpha = (scale/2.1);
      sprite.width = 42;
      sprite.height = 42;
      sprite.x = position.x;
      sprite.y = position.y;
      const obj = {
        sprite: sprite,
        position: position,
        speed: scale,
      }
      return obj;
    },
    createThreat() {
      const threat = this.threat();
      this.threats.push(threat);
      this.stage.addChild(threat.sprite);
      threat.sprite.play();
      this.threatGenerator();
    },
    AU(n) {
      const invaderImages = [invader0, invader1];
      let invaderArray = [];
      for (const image of invaderImages) {
        let texture = PIXI.Texture.from(image);
        invaderArray.push(texture);
      }
      const sprite = new PIXI.AnimatedSprite(invaderArray);
      
      sprite.animationSpeed = 0.2;
      sprite.anchor.set(0.5);
      sprite.zIndex = 1;
      sprite.x = (this.pxWidth / (this.auNum + 1)) * (n + 1);
      sprite.y = this.pxHeight - this.groundPosition;
      sprite.height = 20;
      sprite.width = 20;
      const obj = {
        sprite: sprite,
        position: {
          x: (this.pxWidth / (this.auNum + 1)) * (n + 1),
          y: this.pxHeight - this.groundPosition,
        },
        height: 20,
        width: 20,
        ammo: this.ammoLimit,
      }
      return obj;
    },
    createAUs() {
      for (let i = 0; i < this.auNum; i++) {
        this.createAU();
      }
    },
    createAU() {
      const n = this.aus.length;
      this.aus.push(this.AU(n));
      this.stage.addChild(this.aus[n].sprite);
      this.aus[n].sprite.play();
    },
    control(n) {
      const invaderImages = [invader0, invader1];
      let invaderArray = [];
      for (const image of invaderImages) {
        let texture = PIXI.Texture.from(image);
        invaderArray.push(texture);
      }
      const sprite = new PIXI.AnimatedSprite(invaderArray);
      const startPosition = {x: this.aus[n].position.x, y: this.aus[n].position.y};
      const duration = Math.abs(this.hypotenuse(startPosition.x - this.mouseX, startPosition.y - this.mouseY)) * 4;

      sprite.animationSpeed = 0.5;
      sprite.anchor.set(0.5);
      sprite.zIndex = 1000;
      sprite.height = 20;
      sprite.width = 20;
      sprite.x = startPosition.x;
      sprite.y = startPosition.y;
      sprite.rotation = Math.atan2(sprite.y - this.mouseY, sprite.x - this.mouseX) - (90 * Math.PI/180);
      const obj = {
        sprite: sprite,
        speed: 2,
        time: 0,
        duration: duration,
        startPosition: {x: startPosition.x, y: startPosition.y},
        position: {x: startPosition.x, y: startPosition.y},
        endPosition: {x: this.mouseX, y: this.mouseY},
        height: 12,
        width: 12,
      }
      return obj;
    },
    createControl(n) {
      if (this.aus[n].ammo >= 1) {
        this.aus[n].ammo = Math.floor(this.aus[n].ammo) - 1;
        this.controls.push(this.control(n));
        this.stage.addChild(this.controls[this.controls.length - 1].sprite);
        this.controls[this.controls.length - 1].sprite.play();
      }
    },

    explosion(p) {
      const invaderImages = [invader0, invader1];
      let invaderArray = [];
      for (const image of invaderImages) {
        let texture = PIXI.Texture.from(image);
        invaderArray.push(texture);
      }
      const sprite = new PIXI.AnimatedSprite(invaderArray);

      const duration = 500;
      const startRadius = 10;
      const endRadius = 100;
      sprite.animationSpeed = 0.5;
      sprite.anchor.set(0.5);
      sprite.height = startRadius;
      sprite.width = startRadius;
      sprite.x = p.x;
      sprite.y = p.y;
      const obj = {
        sprite: sprite,
        time: 0,
        duration: duration,
        startRadius: startRadius,
        radius: startRadius,
        endRadius: endRadius,
        position: p,
      }
      return obj;
    },
    createExplosion(p) {
      console.log('create Explosion');
      this.explosions.push(this.explosion(p));
      const n = this.explosions.length - 1
      this.stage.addChild(this.explosions[n].sprite);
      this.explosions[n].sprite.play();
    },


    ammoNum(n) {
      if (this.aus.length > 0) {
        return Math.floor(this.aus[n].ammo);
      } else {
        return 0;
      }
    },

    easeOutQuart(x) { return 1 - Math.pow(1 - x, 4); },
    hypotenuse(w, h) { return Math.sqrt((w * w) + (h * h)); }

    /*loadFromJson(name, pathToImage, data, resolution = parseInt(data.meta.scale, 10)) {

      var loader = new PIXI.loaders.Loader();

      PIXI.loaders.threatsheetParser().call(loader, {
          name: name,
          url: pathToImage,
          data: data,
          isJson: true,
          metadata: {}
        }, function(){});

      return loader;
    },*/
  },

  mounted() {
    this.initUI();
    this.initPixi();
    this.size();
  },
  destroyed() {
    clearTimeout(this.threatTimer);

    for (let i = 0; i < this.threats.length; i++) {
      this.threats[i].sprite.destroy();
    }
    this.threats = [];

    for (let i = 0; i < this.aus.length; i++) {
      this.aus[i].sprite.destroy();
    }
    this.aus = [];

    for (let i = 0; i < this.controls.length; i++) {
      this.controls[i].sprite.destroy();
    }
    this.controls = [];

    for (let i = 0; i < this.explosions.length; i++) {
      this.explosions[i].sprite.destroy();
    }
    this.explosions = [];


    this.ticker.stop();
    this.ticker.destroy();
    this.loader.destroy();
    this.stage.destroy();
    this.renderer.destroy();
    this.renderer = null;
    PIXI.utils.destroyTextureCache();
    document.removeEventListener('keydown', this.keyDown);
    window.removeEventListener("mousemove", this.mousePosition);
    window.removeEventListener("resize", this.size);
  },
};
</script>

<style lang="scss">
@import "../scss/fyr.scss";
.game-container {
  overflow: hidden;
  background-image: linear-gradient(rgba(22,24,28,1) 0%, rgba(124,139,145,1) 100%);
}
#pixi-container canvas{
  display: block;
}
.readout {
  position: absolute;
  top: 2px;
  right: 3px;
  font-size: 11px;
  color: white;
}
.au-ui {
  position: absolute;
  bottom: -20px;
  transform: translateX(-50%);
  display: flex;
  border: 1px solid rgba(255,255,255,0.4);
  transition: 0.5s
}
.ammoIcon {
  width: 8px;
  height: 8px;
  background-color: rgba(255,255,255,0.4);
  margin: 4px;
}
.ammoIcon.active{
  background-color: rgba(255,255,255,1);
}
</style>

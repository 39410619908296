<template>
  <div class="container">
    <div class="value">
      <div class="number" @click="toggle">
        {{number}}
      </div>
      <div v-if="adjustment!==0" :class="['adjustment', adjustment > 0 ? 'neg' : 'pos']">
        {{adjustment}}
      </div>
    </div>
    <div v-if="ui" class="ui">
      <button class="button" @click="adjust(1)">
        +
      </button>
      <button class="button" @click="adjust(-1)">
        -
      </button>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Value",
  props: {
    value: Number,
  },
  components: {
    
  },
  computed: {
    newNumber() {
      return 2;
    }
  },
  data: function () {
    return {
      number: this.value,
      max: 4,
      min: 0,
      ui: false,
      adjustment: '',
    }
  },
  methods: {
    toggle() {
      this.ui = !this.ui;
    },
    adjust(n) {
      this.number = Math.min(Math.max((this.number + n), this.min), this.max);
      const a = this.number - this.value;
      if(a > 0) {
        this.adjustment = '+' + a;
      } else {
        this.adjustment = a;
      }

    }
  }
};

</script>
<style lang="scss" scoped>
  @import "./../scss/fyr.scss";
  .container {
    font-family: 'PublicSans', sans-serif;
    position: relative;
    margin: 0 8px 8px 0;
  }
  .number {
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid rgba($color: #fff, $alpha: 0.2);
    color: white;
    height: 48px;
    width: 48px;
  }
  .adjustment {
    position: absolute;
    top: 2px;
    right: 4px;
    font-size: 12px;
  }
  .ui {
    position: absolute;
    top: 0;
    left: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid red;
    z-index: 2;
  }
  .pos {
    color: greenyellow;
  }
  .neg {
    color: crimson;
  }
  
</style>

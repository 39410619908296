<template>
  <div class="risk-list-container">
    <div v-for="entry in allPlaces" :key="entry.title">
      <RiskBarContainer :title="entry" :value="getAggregatedValue(entry)" :bars="getBarArray(entry)"/>
    </div>
  </div>
</template>

<script>
import RiskBarContainer from '@/components/RiskBarContainer.vue';
export default {
  name: "RiskListDemo",
  components: {
    RiskBarContainer
  },
  data: function () {
    return {
      lists: [
        {
          title: "Mordenkainen's Woe",
          active: false,
          values: [
            {
              title: "Greyhawk",
              value: 71,
            },
            {
              title: "The Pomarj",
              value: 87,
            },
            {
              title: "Iuz",
              value: 97,
            },
            {
              title: "CrystalMist Mountains",
              value: 79,
            },
            {
              title: "Celene",
              value: 52,
            },
            {
              title: "Scarlet Brotherhood",
              value: 85,
            },
            {
              title: "Bright Desert",
              value: 58,
            },
            {
              title: "Erelhei-Cinlu",
              value: 99,
            },
          ]
        },
        {
          title: "Bigby's Wisdom",
          active: false,
          values: [
            {
              title: "Greyhawk",
              value: 76,
            },
            {
              title: "The Pomarj",
              value: 94,
            },
            {
              title: "Iuz",
              value: 99,
            },
            {
              title: "CrystalMist Mountains",
              value: 69,
            },
            {
              title: "Celene",
              value: 41,
            },
            {
              title: "Scarlet Brotherhood",
              value: 82,
            },
            {
              title: "Bright Desert",
              value: 61,
            },
            {
              title: "Erelhei-Cinlu",
              value: 97,
            },
          ]
        },
          {
          title: "Rary's List of Hate",
          active: false,
          values: [
            {
              title: "Greyhawk",
              value: 92,
            },
            {
              title: "The Pomarj",
              value: 84,
            },
            {
              title: "Iuz",
              value: 85,
            },
            {
              title: "CrystalMist Mountains",
              value: 81,
            },
            {
              title: "Celene",
              value: 70,
            },
            {
              title: "Scarlet Brotherhood",
              value: 89,
            },
            {
              title: "Bright Desert",
              value: 47,
            },
            {
              title: "Erelhei-Cinlu",
              value: 91,
            },
            {
              title: "Hardby",
              value: 75,
            },
          ]
        },
         {
          title: "Nystul's Insights",
          active: false,
          values: [
            {
              title: "Greyhawk",
              value: 85,
            },
            {
              title: "The Pomarj",
              value: 79,
            },
            {
              title: "Iuz",
              value: 92,
            },
            {
              title: "CrystalMist Mountains",
              value: 84,
            },
            {
              title: "Celene",
              value: 30,
            },
            {
              title: "Scarlet Brotherhood",
              value: 88,
            },
            {
              title: "Bright Desert",
              value: 56,
            },
            {
              title: "Erelhei-Cinlu",
              value: 96,
            },
          ]
        },
        {
          title: "Otiluke's Rage",
          active: false,
          values: [
            {
              title: "Greyhawk",
              value: 70,
            },
            {
              title: "The Pomarj",
              value: 85,
            },
            {
              title: "Iuz",
              value: 94,
            },
            {
              title: "CrystalMist Mountains",
              value: 72,
            },
            {
              title: "Celene",
              value: 38,
            },
            {
              title: "Scarlet Brotherhood",
              value: 90,
            },
            {
              title: "Bright Desert",
              value: 55,
            },
            {
              title: "Erelhei-Cinlu",
              value: 91,
            },
          ]
        },
          {
          title: "Drawmij's Wisdom",
          active: false,
          values: [
            {
              title: "Greyhawk",
              value: 84,
            },
            {
              title: "The Pomarj",
              value: 75,
            },
            {
              title: "Iuz",
              value: 92,
            },
            {
              title: "CrystalMist Mountains",
              value: 75,
            },
            {
              title: "Celene",
              value: 60,
            },
            {
              title: "Scarlet Brotherhood",
              value: 79,
            },
            {
              title: "Bright Desert",
              value: 68,
            },
            {
              title: "Hardby",
              value: 94,
            },
          ]
        },
      ]
    }
  },
  computed: {
    allPlaces() {
      return [...new Set(this.lists.map(arr => arr.values.map(item => item.title)).flat())];
    },
    placeArr() {
      return this.lists.map(arr => arr.values.map(item => item)).flat();
    },
    
  },
  methods: {
    getData(entry) {
      return { e: entry}
    },
    getAggregatedValue(location) {
      const val = this.placeArr.filter(e => e.title === location);
      const v = Math.round(val.reduce((n, {value}) => n + value, 0) / val.length);
      return v;
    },
    getBarArray(location) {
      const bars = [];
      this.lists.forEach(list => { 
        bars.push({title: 'empty', value: 0});
        list.values.forEach(e  => {if (e.title === location) {
        bars[bars.length-1] = ({title: list.title, value: e.value});
      }}
      )});
      return bars;
    }
  },
};
</script>
<style lang="scss">
  @import "./../scss/fyr.scss";
  .risk-list-container {
    position: relative;
    margin: 32px 0 0 0;
    padding: 100px 10px 120px 10px;
    background: rgba(255,255,255,0.9);
  }
</style>

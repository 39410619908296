<template>
  <div class="splash-container">
    <div class="splash">
      <div class="logo">
        SUPER THREATVIEW
      </div>
      <div class="play">
        Press space to play
      </div>
      <ValueSet/>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import ValueSet from '@/components/ValueSet.vue';
export default {
  name: "Home",
  components: {
    ValueSet,
  },
};
</script>
<style lang="scss">
  @import "./../scss/fyr.scss";
  .splash-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .splash {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .logo {
    font-size: 16vw;
    line-height: 0.8em;
  }
  .play {
    font-size: 5vw;
    color: red;
    text-transform: uppercase;
    padding: 20% 0 0 0;
    animation: blinker 1s linear infinite;
  }
  @keyframes blinker {
    49% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>

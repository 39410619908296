<template>
  <div class="container">
    <Value v-for="val in values" :key="'v' + val" :value="val"/>
  </div>
</template>
<script>
// @ is an alias to /src
import Value from '@/components/Value.vue';
export default {
  name: "ValueSet",
  
  components: {
    Value
  },
  computed: {
    newNumber() {
      return 2;
    }
  },
  data: function () {
    return {
      values: [0, 4, 1, 3, 4],
    }
  },
  methods: {
    toggle() {
      this.ui = !this.ui;
    },
    adjust(n) {
      this.number = Math.min(Math.max((this.number + n), this.min), this.max);
    }
  }
};

</script>
<style lang="scss" scoped>
  @import "./../scss/fyr.scss";
  .container {
    display: flex;
    position: relative;
    //border: 1px solid green;
  }
</style>
